<div class="main">
  <div class="action-buttons" [class.isDialog]="isDialog">
    <div class="actions-wrapper" [class.isDialog]="isDialog">
      <div class="action-buttons">
        @if (submitButtonLabel) {
          <div class="submit-close-button">
            <pk-button
              [label]="submitButtonLabel | translate"
              (click)="submit()"
              [disabledButton]="isSubmitButtonDisabled"
              [icon]="submitButtonIcon"
            />
          </div>
        }
        @if (cancelButtonLabel) {
          <div class="cancel-button">
            <pk-button
              [label]="cancelButtonLabel | translate"
              (click)="cancel()"
              color="secondary"
              [disabledButton]="isCancelButtonDisabled"
              [icon]="cancelButtonIcon"
            />
          </div>
        }
        @if (externalButtonLabel) {
          <div class="continue-button">
            <pk-button
              [label]="externalButtonLabel | translate"
              (click)="external()"
              color="secondary"
              [disabledButton]="isExternalButtonDisabled"
              [icon]="externalButtonIcon"
            />
          </div>
        }
      </div>

      @if (footerTemplate) {
        <div class="footer-template">
          <ng-container [ngTemplateOutlet]="footerTemplate" />
        </div>
      }
    </div>
  </div>
</div>
